import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import InputMask from "react-input-mask";

import CreditCardSecurityModal from "../../components/payment/CreditCardSecurityModal";

import { getCookie } from "../../inc/cookies";
import config from "../../inc/config";
import { priceFormat } from "../../inc/helper";

class PaymentTransactionByCreditCardModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpen3DSecurityModal: false,
      isOpenAddressModal: false,
      appraisalAppointment: {},
      appraisalAppointment: {},
      limit: 0,

      isFreePaymentPanelOpen: false,
      isCreditCardPaymentPanelOpen: false,

      paymentStatusName: "",
      price: 0,
      securityHtmlCode: "",
      transactionProducts: "",

      appraisalAppointmentId: 0,
      cardHolderName: "",
      cardNumber: "",
      cardExpireMonth: "",
      cardExpireYear: "",
      cardCvc: "",

      memberAddresses: [],
      editAddress: null,
      months: ["01","02","03","04","05","06","07","08","09","10","11","12"]
    };
  }

  componentDidMount() {
    this.getMyAppointmentsDetail();
    window.scrollTo(0, 0);

    window.response = (responseData) => {
      this.setState({ isOpen3DSecurityModal: false });
      if (responseData.status == 200) {
        swal({
          dangerMode: true,
          icon: "success",
          title: "Başarılı",
          text: "Ödeme başarı ile gerçekleşti.",
          buttons: "Tamam",
        })
      } else {
        swal({
          dangerMode: true,
          icon: "warning",
          title: "Hata",
          text: responseData.message,
          buttons: "Tamam",
        });
      }
    };
  }

  componentWillUnmount() {}

  getMyAppointmentsDetail() {
    let sessionHash = getCookie("sessionHash");

    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + sessionHash },
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({ isLoading: false });
      }
    };

    fetch(config.restApi + 'appraisalAppointment/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData.status === 200 ? this.setState({isLoading: false, appraisalAppointment: responseData.appraisalAppointment})
      : swal({
        dangerMode: true,
        icon: "warning",
        title: "Hay Aksi",
        text: responseData.message,
        buttons: "Tamam",
      }).then(() => this.props.navigate("/")),
      () =>
      swal({
        dangerMode: true,
        icon: "error",
        title: "Bağlantı Hatası",
        text: "Lütfen internet bağlantınızı kontrol ediniz.",
        buttons: "Yeniden Dene",
      }).then(() => this.getMyAppointmentsDetail()),
      complete()
    );

    fetch(config.restApi + 'memberProductStatus/tr/' + 7, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              {
                this.setState({ limit: responseData.limit });
              }
              break;
            default:
              {
                errorMessages.push(responseData.message);
              }
              break;
          }
          complete();
        },
        () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.')
      );

      fetch(config.restApi + 'appraisalAppointment/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              {
                this.setState({appraisalAppointment: responseData.appraisalAppointment });
              }
              break;
            default:
              {
                errorMessages.push(responseData.message);
              }
              break;
          }
          complete();
        },
        () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.')
      );
  }

  paymentWithIyzipayOnClick = (event) => {
    let sessionHash = getCookie("sessionHash");

    let infoMessage = "";

    if (!this.state.cardHolderName) {
      infoMessage = "Kart sahibi adı soyadı boş geçilemez !";
    } else if (!this.state.cardNumber) {
      infoMessage = "Kart numarası boş geçilemez !";
    } else if (!this.state.cardExpireMonth) {
      infoMessage = "Ay bilgisi boş geçilemez !";
    } else if (!this.state.cardExpireYear) {
      infoMessage = "Yıl bilgisi boş geçilemez !";
    } else if (!this.state.cardCvc) {
      infoMessage = "cardCvc numarası boş geçilemez !";
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: "info",
        title: "Eksik Bilgi",
        text: infoMessage,
        buttons: "Tamam",
      });
    } else {
      this.setState({ isLoading: true });

      const requestOptions = {
        method: "POST",
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({appraisalAppointmentId: this.state.appraisalAppointment.id, cardHolderName: this.state.cardHolderName, cardNumber: this.state.cardNumber, cardExpireMonth: this.state.cardExpireMonth, cardExpireYear: this.state.cardExpireYear, cardCvc: this.state.cardCvc}),
      };

      fetch(config.restApi + 'appraisalAppointment/paymentWithCreditCard/tr/iyzico', requestOptions)
        .then((response) => response.json())
        .then(
          (responseData) => {
            switch (responseData.status) {
              case 200:{
                  this.setState({isLoading: false, isOpen3DSecurityModal: true, securityHtmlCode: responseData.html});
                }
                break;
              default:{
                  this.setState({isLoading: false});

                  swal({
                    dangerMode: true,
                    icon: "warning",
                    title: "Hay Aksi",
                    text: responseData.message,
                    buttons: "Tamam",
                  });
                }
                break;
            }
          }, () => swal({dangerMode: true, icon: "error", title: "Bağlantı Hatası", text: "Lütfen internet bağlantınızı kontrol ediniz.", buttons: "Tamam"})
        );
    }
  };

  paymentWithLidioOnClick = (event) => {
    let sessionHash = getCookie("sessionHash");

    let infoMessage = "";

    if (!this.state.cardHolderName) {
      infoMessage = "Kart sahibi adı soyadı boş geçilemez !";
    } else if (!this.state.cardNumber) {
      infoMessage = "Kart numarası boş geçilemez !";
    } else if (!this.state.cardExpireMonth) {
      infoMessage = "Ay bilgisi boş geçilemez !";
    } else if (!this.state.cardExpireYear) {
      infoMessage = "Yıl bilgisi boş geçilemez !";
    } else if (!this.state.cardCvc) {
      infoMessage = "cardCvc numarası boş geçilemez !";
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: "info",
        title: "Eksik Bilgi",
        text: infoMessage,
        buttons: "Tamam",
      });
    } else {
      this.setState({ isLoading: true });

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({appraisalAppointmentId: this.state.appraisalAppointment.id, cardHolderName: this.state.cardHolderName, cardNumber: this.state.cardNumber, cardExpireMonth: this.state.cardExpireMonth, cardExpireYear: this.state.cardExpireYear, cardCvc: this.state.cardCvc}),
      };

      fetch(config.restApi + 'appraisalAppointment/paymentWithCreditCard/tr/lidio', requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        switch (responseData.status) {
          case 200: {
            this.setState({isLoading: false, isOpen3DSecurityModal: true, securityHtmlCode: responseData.html});
          }
          break;
          default: {
            this.setState({ isLoading: false });

            swal({
              dangerMode: true,
              icon: "warning",
              title: "Hay Aksi",
              text: responseData.message,
              buttons: "Tamam",
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: "error", title: "Bağlantı Hatası", text: "Lütfen internet bağlantınızı kontrol ediniz.",buttons: "Tamam"}));
    }
  };

  paymentWithFreeOnClick = (event) => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    }
    else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({appraisalAppointmentId: this.state.appraisalAppointment.id,})
      };

      fetch(config.restApi + 'appraisalAppointment/paymentWithFree/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({isLoading: false});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text:  'Ücretsiz ödeme işlemi başarıyla gerçekleşti.',
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/appointment-detail?id=' + this.state.appraisalAppointment.id));
          }
          break;
          default: {
            this.setState({isLoading: false});
  
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  openFreePaymentPanel = () => {
    this.setState({isFreePaymentPanelOpen: true, isCreditCardPaymentPanelOpen: false});
  };

  openCreditCardPaymentPanel = () => {
    this.setState({isFreePaymentPanelOpen: false, isCreditCardPaymentPanelOpen: true});
  };

  render() {

    const currentYear = new Date().getFullYear();
    const years = []

    for (let i = 0; i < 10; i++) {
      years.push(currentYear + i) 
    }

    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
      <>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title text-black d-flex justify-content-between pr-2">
                <h4>YENİ EKSPERTİZ RANDEVU BAŞVURUSU</h4>
                <Link className="btn-back text-black" to={"/create-appointment"}> <i className="fa fa-arrow-left"></i> </Link>
              </div>
              <div className="ibox-content">
                <div className="row custom-container mb-3"></div>
                <CreditCardSecurityModal isOpen={this.state.isOpen3DSecurityModal} onRequestClose={() => this.setState({ isOpen3DSecurityModal: false })} securityHtmlCode={this.state.securityHtmlCode}></CreditCardSecurityModal>
                <div className="row overflow-auto">
                  <div className="col">
                    <div className="col">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-2">
                            <div className="d-flex">
                              <i className="fa fa-list icon mr-2 mt-1 text-orange"></i>
                              <h3 className="m-t-none m-b">PAKET BİLGİLERİ</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <h5>{this.state.appraisalAppointment.packageName ? <h4>{this.state.appraisalAppointment.packageName}</h4>: '-'}</h5>
                              <h3>{this.state.appraisalAppointment.price ? priceFormat(this.state.appraisalAppointment.price) : '-'}₺</h3>
                            </div>
                            <div className="d-flex justify-content-between">
                              <h4>Toplam</h4>
                              <h3>{this.state.appraisalAppointment.price ? priceFormat(this.state.appraisalAppointment.price) : '-'}₺</h3>
                            </div>
                            <div className="panel-body p-0">
                              <div className="panel-group" id="accordion">
                                <div className="panel panel-default">
                                  <div className="panel-heading">
                                    <a className="btn-link" data-toggle="collapse" data-parent="#accordion" onClick={this.openFreePaymentPanel}><h4 className="panel-title">Ücretsiz Öde</h4></a>
                                  </div>
                                  <div className={`panel-collapse collapse ${this.state.isFreePaymentPanelOpen ? "show" : ""}`}>
                                    <div className="panel-body">
                                      <div className="ibox">
                                        {this.state.limit > 0 ?
                                        <div className="alert alert-info d-flex justify-content-center">
                                          {this.state.limit} adet ücretsiz ekspertiz kullanım hakkınız var.
                                        </div>
                                        :
                                        <div className="alert alert-info d-flex justify-content-center">
                                          ücretsiz ekspertiz kullanım hakkınız kalmamıştır.
                                        </div>
                                        }
                                      </div>
                                      {this.state.limit > 0 &&
                                      <div className="d-flex justify-content-center">
                                        <button className="btn btn-border-gray font-bold mr-2" onClick={this.paymentWithFreeOnClick}>Ödeme yap </button>
                                      </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="panel panel-default">
                                  <div className="panel-heading">
                                  <a className="btn-link" data-toggle="collapse" data-parent="#accordion" onClick={this.openCreditCardPaymentPanel}><h5 className="panel-title">Kredi Kartı İle Öde</h5></a>
                                  </div>
                                  <div className={`panel-collapse collapse in ${this.state.isCreditCardPaymentPanelOpen ? "show" : ""}`}>
                                    <div className="panel-body">
                                      <div className="d-flex">
                                        <i className="fa fa-credit-card icon mr-2 mt-1 text-orange"></i>
                                        <h3 className="m-t-none m-b">ÖDEME İŞLEMLERİ</h3>
                                      </div>
                                      <label>Kart Sahibi Adı Soyadı<strong className="text-danger ml-1">*</strong></label>
                                      <input autoComplete="off" className="form-control form-input" onChange={(event) => this.setState({cardHolderName: event.target.value.replace(/[^a-zA-Z] | ''+/g, "").trimStart(),})} placeholder="Kart sahibi adı soyadı" value={this.state.cardHolderName}/>
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="mb-2">
                                            <label>Kart Numarası<strong className="text-danger ml-1">*</strong></label>
                                            <div className="input-group">
                                              <InputMask className="form-control form-input" mask={this.state.cardNumber.startsWith("34") || this.state.cardNumber.startsWith("37") ? "9999-999999-99999" : "9999-9999-9999-9999"} onChange={(event) => this.setState({cardNumber: event.target.value.replace(/[^0-9]+/g,""),})} placeholder="Kart numarasını giriniz." value={this.state.cardNumber}></InputMask>
                                              <span className="input-group-addon"> <i className="fa fa-credit-card"></i></span>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-4 mb-2">
                                              <label>Ay<strong className="text-danger ml-1">*</strong></label>
                                              <select className="form-control form-select" onChange={(event) => this.setState({cardExpireMonth: event.target.value.replace(/[^0-9]+/g,""),})} placeholder="Ay" value={this.state.cardExpireMonth} >
                                                <option value="">Ay</option>
                                                {this.state.months.map(month => <option key={month} value={month}>{month}</option>)}
                                              </select>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                              <label>Yıl<strong className="text-danger ml-1">*</strong></label>
                                              <select className="form-control form-select" onChange={(event) => this.setState({cardExpireYear: event.target.value.replace(/[^0-9]+/g,""),})} placeholder="Yıl" value={this.state.cardExpireYear} >
                                                <option value="">Yıl</option>
                                                {years.map(year => <option key={year} value={year}>{year}</option>)}
                                              </select>
                                            </div>
                                            <div className="col-md-4 mb-2">
                                              <label>cardCvc<strong className="text-danger ml-1">*</strong></label>
                                              <input autoComplete="off" className="form-control form-input" maxLength={this.state.cardNumber.startsWith("34") || this.state.cardNumber.startsWith("37") ? 4 : 3} onChange={(event) => this.setState({cardCvc: event.target.value.replace(/[^0-9]+/g,"")})} placeholder="cardCvc" value={this.state.cardCvc}/>
                                            </div>
                                          </div>
                                          <div className="row mt-3">
                                            <div className="col-md-12 d-flex justify-content-start">
                                              <button className="btn btn-border-gray font-bold mr-2" onClick={this.paymentWithIyzipayOnClick}><i className="fa fa-money"></i> Iyzipay İle Öde </button>
                                              {/* <button className="btn btn-border-gray font-bold mr-2" onClick={this.paymentWithLidioOnClick}><i className="fa fa-money"></i> Lidio İle Öde </button> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12 d-flex justify-content-between">
                          <button className="d-flex align-items-center btn border btn-darkgray text-white d-flexible" onClick={() => {this.props.navigate("/appointments")}}><i className="fa fa-arrow-left mr-1"></i>Geri</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default function PaymentTransactionByCreditCard() {
  return (
    <PaymentTransactionByCreditCardModule navigate={useNavigate()} searchParams={useSearchParams()[0]}/>
  );
}
